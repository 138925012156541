import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import { StaticImage } from 'gatsby-plugin-image'
import Divider from '../components/Divider'

import CTABox from '../components/CTABox'
import Section from '../components/Section'
import SectionTitleBlock from '../components/SectionTitleBlock'
import FloatIconFeature from '../components/FloatIconFeature'
import CTALink from '../components/CTALink'

const HeroImage = '../assets/Business/KYT_KYA/Images/KYT_header2.png'
const WalletImg = '../assets/Business/KYT_KYA/Images/wallet_audit.png'

const KYC_KYAImg = '../assets/Business/KYT_KYA/Images/kyc_kya3.png'

const useStyles = makeStyles((theme) => ({
  /**
   * || Reusable Components
   * ======================================= */
  sectionTitle: {
    marginBottom: 15,
    maxWidth: 280,

    textAlign: 'center',

    [theme.breakpoints.up('tablet')]: {
      maxWidth: 320,
    },

    [theme.breakpoints.up('desktop')]: {
      maxWidth: 675,
    },
  },
  sectionDescription: {
    maxWidth: 350,

    color: 'rgba(115, 115, 115, 1)',

    textAlign: 'center',

    [theme.breakpoints.up('tablet')]: {
      maxWidth: 500,
    },

    [theme.breakpoints.up('desktop')]: {
      maxWidth: 600,
    },
  },

  /**
   * || Hero Section
   * ======================================= */
  heroWrapper: {
    position: 'relative',
    top: -48, // Account for the header bar height
    width: '100%',

    [theme.breakpoints.up('desktop')]: {
      paddingBottom: 120,
      overflow: 'hidden',
    },
    [theme.breakpoints.up('desktopXL')]: {
      paddingBottom: 220,
    },
  },
  hero: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    height: 500,
    paddingTop: 96,
    paddingLeft: theme.gutters.mobile,
    paddingRight: theme.gutters.mobile,
    maxWidth: theme.custom.maxWidth,
    margin: 'auto',

    backgroundColor: '#F0F4F8',

    [theme.breakpoints.up('desktop')]: {
      justifyContent: 'flex-start',

      height: 700,
    },
  },
  heroImage: {
    display: 'none !important',

    [theme.breakpoints.up('desktop')]: {
      display: 'block !important',
      position: 'absolute !important',

      width: 'calc(220px + 43%)',
      height: 'auto',
      marginRight: 50,
      right: 'calc(-265px + 5%)',
      top: '65%',

      transform: 'translate(0%, -50%)',
      filter: 'drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.25))',
      borderRadius: 10,
    },

    [theme.breakpoints.up('desktopXL')]: {
      right: '-7%',
      top: '76%',
      width: '60%',
    },
  },
  heroContent: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',

    [theme.breakpoints.up('desktop')]: {
      alignItems: 'flex-start',

      marginLeft: 50,
      marginRight: 50,
      maxWidth: '42%',
    },

    [theme.breakpoints.up('desktopXL')]: {
      maxWidth: '35%',
      minWidth: '500px',
    },
  },
  heroTitle: {
    color: 'black',

    textAlign: 'center',

    [theme.breakpoints.up('desktop')]: {
      textAlign: 'left',
    },
  },
  heroDescription: {
    margin: '20px 0px',
    maxWidth: 350,

    color: 'rgba(115, 115, 115, 1)',

    textAlign: 'center',
    whiteSpace: 'pre-line',

    [theme.breakpoints.up('tablet')]: {
      maxWidth: 450,
    },

    [theme.breakpoints.up('desktop')]: {
      maxWidth: 'unset',

      textAlign: 'left',
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },

  /**
   * || Crypto Wallet Audit Section
   * ======================================= */
  walletAuditSection: {
    marginTop: 50,

    [theme.breakpoints.up('desktop')]: {
      flexDirection: 'row',
      justifyContent: 'space-evenly',

      width: '100%',

      maxWidth: theme.custom.maxWidth,
      margin: 'auto',
    },

    [theme.breakpoints.up('desktopXL')]: {
      marginTop: 100,
    },

    '& div': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',

      marginTop: 50,

      [theme.breakpoints.up('desktop')]: {
        alignItems: 'flex-start',

        marginTop: 0,
      },
    },

    '& p': {
      maxWidth: 350,

      [theme.breakpoints.up('desktop')]: {
        maxWidth: 500,
      },
    },
  },
  walletImage: {
    maxWidth: 350,
    width: '100%',

    [theme.breakpoints.up('desktop')]: {
      width: '45%',
      maxWidth: 450,
      marginRight: 50,
    },
  },

  /**
   * || KYC and KYA/KTC Section
   * ======================================= */
  kycSection: {
    flexDirection: 'column-reverse',

    maxWidth: theme.custom.maxWidth,
    margin: 'auto',
    marginTop: 100,

    [theme.breakpoints.up('desktop')]: {
      flexDirection: 'row',
      justifyContent: 'space-evenly',

      marginBottom: 150,
    },

    [theme.breakpoints.up('desktopXL')]: {
      marginTop: 150,
      marginBottom: 200,
    },
  },
  kycImage: {
    maxWidth: 450,
    width: '100%',

    [theme.breakpoints.up('desktop')]: {
      width: '45%',
      maxWidth: 550,
    },
  },
  kycColumnLeft: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    marginTop: 50,

    textAlign: 'center',
    [theme.breakpoints.up('desktop')]: {
      alignItems: 'flex-start',

      maxWidth: 550,
      marginRight: 50,

      textAlign: 'left',
    },
  },
}))

export default function KYT_KYA() {
  const classes = useStyles()
  return (
    <main className={classes.main}>
      <section className={classes.heroWrapper}>
        <div className={classes.hero}>
          <div className={classes.heroContent}>
            <Divider style={{ marginBottom: 12 }} />
            <Typography variant='h1' className={classes.heroTitle}>
              Making Truly Anonymous KYC Possible
            </Typography>

            <Typography variant='body1' className={classes.heroDescription}>
              One of the big advantages to transacting in cryptocurrencies is
              being anonymous. This creates big issues with regulators who need
              to know where the money is coming from and who&apos;s spending it.
              {' \n\n'}
              CycurID&apos;s revolutionary approach to Know Your Transaction
              (KYT) and Know Your Address (KYA) protocols removes the downsides
              of anonymous digital currencies while preserving truly anonymous
              commerce.
            </Typography>
            <div className={classes.buttonContainer}>
              <CTALink
                className={classes.signUpButton}
                href={process.env.GATSBY_DASHBOARD_SIGNUP_LINK}
                target='_blank'
                rel='noopener'
              >
                Sign Up Now
              </CTALink>
            </div>
          </div>
          <StaticImage
            src={HeroImage}
            alt='CycurID dashboard KYT and KYA panel screenshot'
            className={classes.heroImage}
            loading='eager'
            quality={100}
          />
        </div>
      </section>

      <Section className={classes.walletAuditSection} gutterVariant='padding'>
        <StaticImage
          className={classes.walletImage}
          src={WalletImg}
          alt='Imme app wallet feature screenshots, including loading and sending funds'
          placeholder='tracedSVG'
          objectFit='fill'
          quality={100}
        />
        <div>
          <SectionTitleBlock
            title='Crypto Wallet Auditing'
            description={
              <>
                Scan crypto wallet addresses in real time to see if they contain
                funds that have been used for money laundering, fraud, have
                touched the dark web, and more.{' \n\n'}Our AI-powered software
                runs audit checks on these crypto wallets, verifying if the
                funds they contain are trustworthy.
              </>
            }
          />
        </div>
      </Section>

      <Section className={classes.kycSection} gutterVariant='padding'>
        <div className={classes.kycColumnLeft}>
          <SectionTitleBlock title='Combine KYC with KYA/KTC to comprehensively scrub potential customers.' />
          <FloatIconFeature description='Link Reusable Encrypted Zero-Knowledge Identity Tokens with individual crypto wallets to ensure individuals are screened and verified, and are paying with legitimate funds.' />
          <FloatIconFeature description='Maintain anonymity through zero-knowledge protocols, but still realize the security of rigorous screening practices, and stay compliant.' />
        </div>
        <StaticImage
          className={classes.kycImage}
          src={KYC_KYAImg}
          alt='CycurID dashboard screenshot of transaction risk assessment and scoring feature'
          placeholder='tracedSVG'
          quality={100}
        />
      </Section>

      <CTABox
        title='Be a leader in taking crypto mainstream.'
        description='Maximize the potential of blockchain protocols while staying compliant with regulators. Build the bridge between mainstream financial markets and the power of digital currencies with CycurID.'
        style={{ marginTop: 100, marginBottom: 150 }}
      />
    </main>
  )
}
